@use '@angular/material' as mat;
@use 'variables' as *;

@use 'flex' as flex;
@use 'appbase' as appbase;
@use 'apploader' as apploader;
@use 'buttons' as buttons;
@use 'cards' as cards;
@use 'content' as content;
@use 'dialog' as dialog;
@use 'forms' as forms;
@use 'tables' as tables;
@use 'fonts/opensans/' as opensans;
@use 'material-fixes' as materialFixes;
@use 'print-submission' as printSubmission;

@import './styles/themes/fpar/variables.scss';

@include mat.core();
@include mat.elevation-classes();
@include mat.app-background();
@include mat.strong-focus-indicators();
@include mat.all-component-themes($app-light-theme);
@include mat.typography-hierarchy($app-typography);
@include mat.strong-focus-indicators-theme($app-light-theme);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

body.dark {
  @include mat.all-component-colors($app-dark-theme);

  a,
  a:visited {
    color: #429dff;
  }

  .mat-mdc-menu-panel {
    color: white;
  }
}

main > ng-component {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 100%;
}

.nav-menu-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

input.mat-input-element:-internal-autofill-selected {
  background: transparent !important;
}
