$client-color-1: #0b8d94;
$client-color-2: #f3b927;
$client-color-3: #38408b;
$client-color-4: #858585;
$client-color-5: #eaeaea;
$hhsblue: #0b5ba7;

$darkgrey: #363636;
$dimgrey: #5c6067;
$grey: $client-color-4;
$lightgrey: $client-color-5;

$green: #009650;
$lightgreen: #6fdfab;

$indigo: #3f51b5;
$red: #d21414;

$app-background: #fcfcfc;
$app-background-accent: $hhsblue;

$app-scrollbar-color: rgba(91, 103, 113, 0.7);
$app-scrollbar-inactive-color: rgba(91, 103, 113, 0.4);
$app-scrollbar-active-color: rgba(91, 103, 113, 1);

$app-loader-color: $indigo;

$nav-background: #fdfffe;
$nav-color: $darkgrey;
$nav-link-color: #ffffff;
$nav-separator-color: #{$nav-color}+ '3F';

$default-border-radius: 3px;

$app-menu-background: $darkgrey;
$app-menu-color: #ededed;
$app-menu-button-border: 1rem solid transparent;
$app-menu-button-active-background: white;
$app-menu-button-hover-background: rgba(255, 255, 255, 0.5);
$app-menu-button-active-background-dark: black;
$app-menu-button-hover-background-dark: rgba(0, 0, 0, 0.5);
$app-menu-button-hover-border: $client-color-1;

$app-border-color: rgba(0, 0, 0, 0.12);
$app-link-color: #157f84;

$app-footer-color: #adadad;
