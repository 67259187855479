.action-bar {
  padding: 0 1.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-flow: row wrap;
  justify-content: stretch;

  [action-bar-left],
  [action-bar-right] {
    display: flex;
    flex: 1 1 30%;
    align-items: center;

    &:empty() {
      display: none;
    }

    fieldset {
      padding: 0;
    }

    &[action-bar-right] {
      justify-content: flex-end;

      & > * {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }

      button {
        margin-left: 0.5rem;
      }
    }

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
  }
}
