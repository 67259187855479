@import './themes/fpar/variables';

$loader-width: 100px;

.loader-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  background-color: $app-background;
  transition: all 0.2s ease-in-out 0.1s;
  opacity: 1;
  /*-webkit-transform: translate3d(0,0,0);*/

  .progressring-group {
    margin-top: 25vmin;
    display: none;

    .progressring {
      position: relative;
      /*margin: 25vmin auto;*/
      width: 64px;
      height: 84px;
    }

    .circle {
      position: absolute;
      width: 60px;
      height: 60px;
      opacity: 0;
      transform: rotate(225deg);
      animation-iteration-count: infinite;
      animation-name: progressring-circle-orbit;
      animation-duration: 5.5s;

      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 5px;
        background: #e70033;
      }
      &:nth-child(2) {
        animation-delay: 240ms;
      }
      &:nth-child(3) {
        animation-delay: 480ms;
      }
      &:nth-child(4) {
        animation-delay: 720ms;
      }
      &:nth-child(5) {
        animation-delay: 960ms;
      }
    }
  }

  .label {
    text-align: center;
    font-weight: 400;
    letter-spacing: -0.095rem;
    color: #777777;
    font-size: 3rem;
    margin-top: 1rem;
  }

  .spinner {
    position: relative;
    margin: 25vmin auto 0;
    width: $loader-width;
    height: $loader-width;
    animation: mat-progress-spinner-linear-rotate 2s linear infinite;

    .circular {
      position: absolute;
      width: $loader-width;
      height: $loader-width;
      transform: rotate(-90deg);
      top: 0;
      left: 0;
      transform-origin: center;
      overflow: visible;
    }

    .path {
      stroke: $app-loader-color;
      fill: transparent;
      transform-origin: center;
      transition: stroke-dashoffset 225ms linear;
      transition-property: stroke;
      animation-duration: 4s;
      animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      animation-iteration-count: infinite;
      animation-name: mat-progress-spinner-stroke-rotate-100;
      stroke-dasharray: 282.743px;
      stroke-width: 10%;
    }
  }
}

/*************************************************************************/
/*************************************************************************/

@keyframes progressring-circle-orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }

  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }

  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out;
  }

  39% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }

  70% {
    transform: rotate(815deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }

  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out;
  }

  76% {
    transform: rotate(945deg);
    opacity: 0;
  }

  100% {
    transform: rotate(945deg);
    opacity: 0;
  }
}

@keyframes mat-progress-spinner-linear-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes mat-progress-spinner-stroke-rotate-100 {
  0% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(0);
  }
  12.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(0);
  }
  12.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(72.5deg);
  }
  25% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(72.5deg);
  }
  25.0001% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(270deg);
  }
  37.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(270deg);
  }
  37.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(161.5deg);
  }
  50% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(161.5deg);
  }
  50.0001% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(180deg);
  }
  62.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(180deg);
  }
  62.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(251.5deg);
  }
  75% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(251.5deg);
  }
  75.0001% {
    stroke-dashoffset: 268.60617px;
    transform: rotate(90deg);
  }
  87.5% {
    stroke-dashoffset: 56.54867px;
    transform: rotate(90deg);
  }
  87.5001% {
    stroke-dashoffset: 56.54867px;
    transform: rotateX(180deg) rotate(341.5deg);
  }
  100% {
    stroke-dashoffset: 268.60617px;
    transform: rotateX(180deg) rotate(341.5deg);
  }
}
