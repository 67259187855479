@import './themes/fpar/variables';

.mat-mdc-dialog-container {
  .mat-mdc-panel-status {
    z-index: 100;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    min-height: 1rem;
  }

  .mat-mdc-dialog-content {
    max-height: 65vh;
  }

  .mat-mdc-dialog-actions {
    display: flex;
    gap: 0.5rem;
    padding: 1rem;
    background: #{$lightgrey}55;

    &:has(.actions-left) {
      justify-content: space-between;
    }

    .actions-left {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
