@import 'variables';

fieldset {
  font-size: 0.85rem;
}

.mdc-form-field > label {
  margin-bottom: 0;
}

.mat-mdc-form-field {
  font-size: inherit !important;
  padding-right: 1rem;

  &.fill-white .mdc-text-field--outlined {
    background: white;
  }

  .mat-mdc-form-field-icon-prefix,
  [dir='rtl'] .mat-mdc-form-field-icon-suffix {
    padding: 0.25rem 0 0.25rem 0.5rem;
  }

  .mat-mdc-form-field-icon-suffix,
  [dir='rtl'] .mat-mdc-form-field-icon-prefix {
    padding: 0.25rem 0.5rem 0.25rem 0;
  }

  &.width-auto .mat-mdc-form-field-infix {
    width: auto;
  }

  &:not(:only-of-type) {
    padding-bottom: 1rem;
  }

  .mat-mdc-input-element {
    font-size: inherit !important;
  }

  &.mat-mdc-form-field-100-percent {
    width: 100%;
  }

  &.mat-mdc-form-field-50-percent {
    width: 50%;
  }

  &.mat-mdc-form-field-30-percent {
    width: 30%;
  }

  &.mat-mdc-form-field-25-percent {
    width: 25%;
  }

  &.mat-mdc-form-field-20-percent {
    width: 20%;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.2) !important;
  }

  .mdc-notched-outline__notch {
    border-left: none !important;
  }
}

.mdc-radio:not(.mdc-radio--disabled) + label,
.mdc-checkbox:not(.mdc-checkbox--disabled) + label {
  cursor: pointer;
}

.mat-mdc-form-field-error {
  margin: 0 -0.5rem;
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  font-size: inherit !important;

  mat-label {
    font-size: inherit !important;
  }
}

.mat-mdc-option {
  font-size: 0.85rem;

  .mdc-list-item__primary-text {
    display: block;
    max-width: calc(100% - 1px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }
}

.mat-mdc-radio-button {
  .mdc-form-field:has(p) {
    align-items: flex-start;
  }
}
