@use '@angular/material' as mat;
@import 'variables';

.mat-mdc-card {
  display: flex;
  flex-flow: column nowrap;
  border-radius: 0;

  .mat-mdc-card-header {
    background: #eaeaea80;
    border-bottom: 0.0625rem solid rgb(241, 237, 237);

    .mat-mdc-card-header-text {
      flex: 1 1 100%;
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      margin-bottom: 1rem;
    }

    .mat-mdc-card-avatar {
      padding: 0.5rem;
      color: white;
      background-color: $indigo;
    }

    .mat-mdc-card-title {
      font-size: 1rem;
      font-weight: 600;
      opacity: 0.8;
    }

    .mat-mdc-card-subtitle {
      margin-bottom: 0;
      font-size: 0.8rem;
      flex: 1 1 100%;
    }
  }

  .mat-mdc-card-content {
    min-height: 10rem;
    padding: 1rem 1.5rem;
    flex-grow: 1;
    font-size: 0.85rem;
  }

  .mat-mdc-card-actions {
    font-weight: 600;
    text-transform: uppercase;
    gap: 0.5rem;
  }
}
