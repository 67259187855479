@import 'variables';

.mat-mdc-table .mat-mdc-header-row {
  background: #f9f9f9 !important;
  font-weight: 500;
}

.mat-mdc-table .mat-mdc-header-cell {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.7rem;
  border-bottom-color: rgba(0, 0, 0, 0.275);
  color: rgba(0, 0, 0, 0.65);

  &.wrap-header-text .mat-sort-header-content {
    text-align: left;
  }
}

.mat-mdc-table .mat-mdc-cell,
.mat-mdc-table .mat-mdc-footer-cell {
  font-size: 0.8rem;

  .material-icons,
  .material-icons-outlined {
    vertical-align: bottom;
    margin-right: 0.25rem;

    &.icon-color-green {
      color: $client-color-1;
    }
  }
}

.mdc-data-table__row:last-child
  .mdc-data-table__cell:not(.mat-mdc-footer-cell) {
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.12) !important;
}
