@use '@angular/material' as mat;
@import './themes/fpar/variables';

// Include the Angular Material theming functions and mixins
@include mat.core();

.print-layout-container {
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  counter-reset: elementcounter;
}

.print-layout-inner-container {
  max-width: 80rem;
  margin: auto;
}

.print-btn-wrap {
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 5;

  .mat-mdc-button {
    background-color: $client-color-1;
    padding: 1.25rem !important;
  }
}

.print-btn {
  display: flex;
  padding: 1rem;
  background-color: $client-color-5;
  border: 0.0625rem solid $app-border-color;
  border-width: 0.0625rem 0;
  color: white !important;
  font-size: 90%;
  border-radius: 0px 0px 4px 4px !important;
  @include mat.elevation-classes();
}

.print-layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 96px 194px 1fr 48px;
  gap: 12px;
  margin: 36px;
}

.print-footer {
  display: flex;
  justify-content: space-between;
  grid-column: 1/13;
  grid-row: 4;
  width: 100%;
}

.print-title-footer {
  align-self: center;
  margin: 0px;
  height: fit-content;
}

.print-layout-backround {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 96px 194px 418px 1fr;
  gap: 12px;
  height: 720px;
  margin: 36px;
}

.print-layout-cover-background {
  grid-column: 4/13;
  grid-row: 1/5;
  height: 100%;
  width: 100%;
}

.print-logo {
  width: 109px;
  margin-top: 9.625px;
  grid-column: 1/3;
}

.print-project-director-label,
.print-contact-person-label,
.print-project-director-label {
  margin: 0px;
}

.print-project-director {
  background-color: white;
  grid-column: 4 / 8;
  grid-row: 1;
  height: fit-content;
  margin: 0px 0px 0px 0px;
}

.print-contact-person {
  background-color: white;
  grid-column: 9 / 13;
  grid-row: 1;
  height: fit-content;
  margin: 0px 0px 0px 0px;
}

.print-project-director-name,
.print-contact-person-name,
.print-project-director-date {
  font-weight: bold;
  margin: 0px;
}

.print-title {
  grid-column: 1 /13;
  grid-row: 2;
}

.print-title-intro {
  font-size: 16px;
  margin: 0px;
}

.print-title-grant {
  background-color: white;
  font-size: 43px;
  font-weight: bold;
  margin-top: 0px;
  width: fit-content;
}

.print-cover-sheet-info {
  grid-column: 4 /13;
  grid-row: 3;
  table-layout: fixed;
  height: fit-content;
  border-style: solid;
  border-color: black;
  border-width: 1px 0px;

  td,
  th {
    border-style: solid;
    border-color: black;
    border-width: 1px 0px;
  }

  td:nth-child(1) {
    font-weight: bold;
  }
}

.print-last-updated {
  background-color: white;
  grid-column: 4 /13;
  grid-row: 4;
  height: fit-content;
}

.print-numbered-heading-1 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 12px;
  font-weight: 100;
  font-size: 29px;
  grid-column: 1/14;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.print-numbered-heading-1-item {
  padding: 0px;
  font-size: 29px;
  font-weight: bold;
  margin: 0px;
  line-height: 1.25;
  grid-column: 1/4;
  width: 100%;
}

.print-numbered-heading-1-item-title {
  grid-column: 4/13;
  line-height: 1.25;
}

.print-page-content-wrapper {
  grid-column: 1/13;
}

.print-notes {
  grid-column: 1/4;
  grid-row: 2;
  margin-top: 84px;
}

.print-toc-header {
  font-weight: 100;
  font-size: 29px;
  margin: 0px;
  padding: 0px;
  grid-column: 1/4;
  grid-row: 1;
}

.print-toc {
  grid-column: 4/13;
  grid-row: 1/4;
  height: 100%;
  table-layout: fixed;
  margin: 0px;
  padding: 68px 0px;

  td {
    padding-left: 29px;
  }

  td:first-child {
    font-size: 29px;
    color: gray;
    font-weight: bold;
    text-align: right;
    padding-left: 0px;
  }
}

.print-notes-heading {
  font-size: 16px;
  font-weight: bold;
  border-style: solid;
  border-color: black;
  border-width: 1px 0px 0px 0px;
}

.print-table {
  vertical-align: top;
  grid-column: 4/13;
  grid-row: 2/4;
  height: fit-content;
  table-layout: fixed;
  margin-top: 84px;

  thead > tr {
    vertical-align: bottom !important;
  }

  tbody,
  td,
  tr {
    text-align: right;
    height: fit-content;
    vertical-align: top;
  }

  th {
    text-align: right;
    border-style: solid;
    border-color: lightgray;
    border-width: 0px 0px 0px 0px;
    font-weight: bold;
  }

  th:first-child,
  td:first-child {
    text-align: left;
  }

  tr {
    border-style: solid;
    border-color: lightgray;
    border-width: 1px 0px 1px 0px;
  }

  tr:last-child {
    border-style: solid;
    border-color: lightgray;
    border-width: 0px 0px 0px 0px;
  }
}

.print-submission-comments {
  grid-column: 4/13;
  grid-row: 3/4;
}

.print-submission-comments-list {
  color: black;
  font-size: 16px;
  line-height: 1.5;
  margin: 0px 14px 14px 0px;
}

.print-submission-comments-list-item::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-size: 16px;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 16px; /* Also needed for space (tweak if needed) */
  margin-left: -20px; /* Also needed for space (tweak if needed) */
}

.print-submission-comments-list-item {
  list-style: none;
  padding: 0px;
  font-size: 16px;
  line-height: 1.5;
  margin: 0px 0px 9px 20px;

  span {
    padding-left: 0px;
    position: relative;
    left: 14px;
    line-height: 1.5;
  }
}
@media print {
  .print-btn-wrap {
    display: none;
  }

  .print-layout-container {
    overflow: visible;
    height: 100%;
  }

  .print-layout {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 96px 194px 525px 48px;
    gap: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px;
  }

  .print-table {
    font-size: 9px;
  }

  .print-notes {
    font-size: 14px;
  }

  .print-footer,
  .print-last-updated {
    height: 100%;
    margin: 54px 0px 0px 0px;
    padding: 0px;
    bottom: 0;
  }

  .page-break {
    page-break-after: always;
  }

  .no-page-break-between {
    page-break-inside: avoid;
  }

  @page {
    size: letter;
  }
}
