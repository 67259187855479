@import 'variables';

a {
  &:focus,
  &:focus-visible {
    @include primary-outline();
  }
}

.mat-icon + span:last-of-type {
  margin-left: 0.5rem;
}

.mat-tooltip {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.8rem !important;
  font-weight: 500;
}

.mat-sort-header-container.mat-focus-indicator {
  display: inline-flex;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  border-radius: 0 !important;

  [mat-mdc-menu-item-container] {
    display: inline-flex;
    height: auto;
    min-height: unset;
    width: auto;
    padding: 0;
  }

  .mat-mdc-menu-item:not([mat-mdc-menu-item-container]) {
    display: flex;
    align-items: center;

    .mat-icon {
      margin-right: 0.5rem;
    }
  }

  &.nav-menu {
    max-width: 23rem;
    width: 20rem;

    .mat-mdc-menu-content {
      padding: 0;
    }

    .mat-mdc-menu-item .mat-icon {
      margin-right: 0.25rem;
    }
  }
}

.mat-step-header {
  &.disable-step {
    pointer-events: none;
  }
}

.mat-tab-body-wrapper {
  height: 100%;
}

.mat-drawer-inner-container {
  display: flex;
  flex-flow: column nowrap;
}

.mat-slide-toggle-content {
  display: flex;
}

.mat-mdc-progress-bar {
  .mdc-linear-progress__bar {
    height: auto;
  }

  .mat-progress-bar-fill::after,
  .mdc-linear-progress__bar-inner {
    background-color: $client-color-3;
    border-color: $client-color-3;
  }

  &.progress-bar-green-color {
    .mat-progress-bar-fill::after,
    .mdc-linear-progress__bar-inner {
      background-color: $client-color-1;
      border-color: $client-color-1;
    }
  }

  &.no-background-buffer {
    .mdc-linear-progress__buffer,
    .mat-progress-bar-background.mat-progress-bar-element,
    .mat-progress-bar-buffer.mat-progress-bar-element {
      display: none;
    }
  }

  &.benchmark {
    .mdc-linear-progress__primary-bar {
      .mdc-linear-progress__bar-inner {
        border-top-width: 2rem;
      }
    }
  }

  &.measure {
    .mdc-linear-progress__primary-bar {
      .mdc-linear-progress__bar-inner {
        border-top-width: 0.75rem;
      }
    }
  }
}

.mat-error {
  color: $red;
}

.mat-select-placeholder {
  color: $grey;
}

.mat-divider {
  padding: 0.5rem 0 1rem;
}

.mat-mdc-chip-set {
  .mat-mdc-chip {
    font-weight: 600;
    border: 0.0625rem solid $app-scrollbar-inactive-color;

    &.mat-mdc-standard-chip {
      background: white;

      &:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
        color: inherit;
      }
    }
  }

  &[readonly] {
    .mat-mdc-chip {
      pointer-events: none;
      user-select: none;

      &:active {
        box-shadow: 0;
      }

      &:focus::before,
      &:focus::after {
        border-color: transparent;
        background: transparent;
      }
    }
  }
}
